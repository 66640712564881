import '../stylesheets/styles.scss'
import jquery from 'jquery';
import SimpleMarquee from 'jquery.simplemarquee';
// import distortText from './distort.js';
import './cursor.js';
import Menu from './menu';
import {preloader} from './preloader';

window.jQuery = jquery;
window.$ = jquery;

$(document).ready(function(){

    //consoleText(['Transformação', 'Revolução', 'Evolução'], 'text',['tomato','rebeccapurple','lightblue']);
    consoleText(['Transformação', 'Revolução', 'Evolução'], 'text');
    
    $('.texto').simplemarquee({
        speed: 150,
        cycles: 'Infinity'
    });

    $("#checkbox-menu").on('click', toggleMenu);

    formContato.init();

    applyGlitchEffect("#titulo-clientes");
    applyGlitchEffect("#titulo-contato");

    $("#contato").submit(function() {
      var nome = $("#input-1").val();
      var email = $("#input-2").val();
      var assunto = $("#input-3").val();
      var mensagem = $("#input-4").val();
      $("#status").html("<img src='loader.gif'/>");
      
      $("#contato").hide();
      $("#contato-sucesso").show();
      /*
      $.post('envia-email.php', {nome: nome, email: email, assunto: assunto, mensagem: mensagem }, function(status) {
        const response = jQuery.parseJSON(status);
        console.log(response);
        if (response.success) {
          $("#contato").hide();
          $("#contato-sucesso").show();
        } else {
          alert('Ocorreu um erro ao enviar o e-mail, tente novamente em alguns minutos.');
        }
      });
      */
    });

    new distortText('.distort-1');
    new distortText('.distort-2');

    // const menuEl = document.querySelector('.menu-imagem');
    // new Menu(menuEl);
    preloader('.menu__item').then(() => {
      // initialize the smooth scroll
      // const scroll = new LocomotiveScroll({el: menuEl, smooth: true});
  
      // initialize custom cursor
      // const cursor = new Cursor(document.querySelector('.cursor'));
  
      // initialize menu
      
    });

    $("#menu a").on('click', handleMenuClick);
})

function handleMenuClick(e) {
  const item = $(this);
  const targetElement = item.data("anchor");
  
  $([document.documentElement, document.body]).animate({
    scrollTop: $(targetElement).offset().top - 300
  }, 100, function() {
    
    $("#menu").slideUp(1000, function(){
      $("#checkbox-menu").prop("checked", false);
      $("#checkbox-menu").val(false);
    });
    $([document.documentElement, document.body]).animate({
      scrollTop: $(targetElement).offset().top - 100
    }, 1000);
  });

  
}

function applyGlitchEffect(id) {
  $(id).append("<div class='glitch-window'></div>");
  $( id + " h3.glitched" ).clone().appendTo( id + " .glitch-window" );
}

function consoleText(words, id, colors) {
  if (colors === undefined) colors = ['#fff'];
  var visible = true;
  var con = document.getElementById('console');
  var letterCount = 1;
  var x = 1;
  var waiting = false;
  var target = document.getElementById(id)
  target.setAttribute('style', 'color:' + colors[0])
  window.setInterval(function() {

    if (letterCount === 0 && waiting === false) {
      waiting = true;
      target.innerHTML = words[0].substring(0, letterCount)
      window.setTimeout(function() {
        var usedColor = colors.shift();
        colors.push(usedColor);
        var usedWord = words.shift();
        words.push(usedWord);
        x = 1;
        target.setAttribute('style', 'color:' + colors[0])
        letterCount += x;
        waiting = false;
      }, 1300)
    } else if (letterCount === words[0].length + 1 && waiting === false) {
      waiting = true;
      window.setTimeout(function() {
        x = -1;
        letterCount += x;
        waiting = false;
      }, 1300)
    } else if (waiting === false) {
      target.innerHTML = words[0].substring(0, letterCount)
      letterCount += x;
    }
  }, 60)
  window.setInterval(function() {
    if (visible === true) {
      con.className = 'console-underscore hidden'
      visible = false;

    } else {
      con.className = 'console-underscore'

      visible = true;
    }
  }, 400)
}

const formContato = {
  input_atual: 1,
  
  init: function(){
    $(".proxima-etapa").on('click', formContato.proximaEtapa);
    $(".enviar-formulario").on('click', formContato.enviarFormulario);
  },

  proximaEtapa: function(){
    // const id = $(this).attr("id");
    // const atual_id = id.split("-")[1];
    // const prox_id = atual_id + 1;
    const prox_input = formContato.input_atual + 1;
    const index = formContato.input_atual;
    
    $("#input-" + index).addClass("oculto");
    $("#input-" + prox_input).removeClass("oculto");
    $("#nav-" + index).removeClass("ativo");
    $("#nav-" + prox_input).addClass("ativo");
    formContato.input_atual = prox_input;
    if (prox_input == 4) {
      $(".proxima-etapa").css('display', 'none');
      $(".enviar-formulario").css('display', 'block');
    }
    return false;
  },

  enviarFormulario: function(){

  }
};

function toggleMenu(){
  if ($(this).is(":checked"))
    $(".menu").css("display","flex");
  else
    $(".menu").css("display","none");
}




function distortText(el){
  this.runs = 0;
  this.charSet = 'abcdefghijklmnopqrstuvwxyz1234567890';
  this.curr = $(el);
  this.orig = $(el).text();
  this.sib = false;
  
  this.init = function(){
    const self = this;
    this.sib = setInterval(function(){
      self.distort(self.curr);
    }, 100);    
  }

  this.distort = function(i){
    //MAINTAINS SOME READABILITY IN THE TEXT BY ONLY ALLOWING 3 CHARACTERS TO BE DISTORTED
    if (this.runs >= 2){
      this.runs = 0;
      i.text(this.orig);
      return;
    }
    //GET EACH INDIVIDUAL CHARACTER
    let chars = i.text().split('');
    //GET A RANDOM CHARACTER FROM THE TEXT
    let rand = Math.floor(Math.random() * (chars.length));
    //GET A RANDOM REPLACEMENT CHARACTER
    let randRep = Math.floor(Math.random() * (this.charSet.length));
    //CHECK TO MAKE SURE THAT THE NEW CHARACTER IS DIFFERENT FROM THE OLD
    if(chars[rand] != this.charSet[randRep] && chars[rand] != ' '){
      chars[rand] = this.charSet[randRep];
    } else {
      this.distort(i);
    }
    //UPDATE TEXT
    i.text(chars.join(''));
    this.runs += 1;
  }

  this.init();
}


